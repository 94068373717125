<template>
    <v-text-field
        v-model="date"
        class="app-date-field"
        :autofocus="autofocus"
        :label="label"
        :hide-details="hideDetails"
        :rules="rules"
        :readonly="readonly"
        :required="required"
        :dense="dense"
        :outlined="outlined"
        :disabled="disabled">
        <template v-slot:append>
            <v-menu
                v-if="!readonly && !disabled"
                v-model="showDatePicker"
                offset-y
                :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                    <v-btn
                        icon
                        color="primary"
                        dark
                        elevation="0"
                        v-on="on">
                        <v-icon>mdi-calendar</v-icon>
                    </v-btn>
                </template>
                <v-date-picker v-model="pickerDate" />
            </v-menu>
        </template>
    </v-text-field>
</template>

<script>
import { isEmpty } from "@/services/objectUtility"
import { DateTime } from "luxon";
import { parse, format, localize } from "@/services/dateUtility"

// Consider the following represents a full date with timezone:
// 2019-09-28T18:00:00+08:00

export default {
    props: {
        label: {
            type: String,
            default: null,
        },
        value: {
            type: String,
            default: null,
        },
        hasDefaultDate: {
            type: Boolean,
            default: false,
        },
        hideDetails: {
            type: Boolean,
            default: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            date: null,
            showDatePicker: false,
            rules: [
                v => (!this.required || !isEmpty(v)) || "Date is required",
                v => {
                    if (isEmpty(v)) {
                        return true;
                    }
                    let date = parse(v);
                    return date.isValid && date.year < 10000 || "Invalid date";
                }
            ],
        };
    },

    computed: {
        // v-date-picker requires the value be formatted yyyy-MM-dd. This computed property
        // converts the date value to and from this format, defaulting to today if the current date
        // is not specified.
        pickerDate: {
            get() {
                let dateTime = isEmpty(this.date) || !this.valid ?
                    localize(DateTime.now()) :
                    parse(this.date);

                return dateTime.toFormat("yyyy-MM-dd");
            },
            set(value) {
                this.showDatePicker = false;
                if (isEmpty(value)) {
                    return;
                }

                let dateTime = localize(DateTime.fromFormat(value, "yyyy-MM-dd"));
                if (dateTime.isValid) {
                    this.date = format(dateTime);
                }
            },
        },
        valid() {
            return this
                .rules
                .every(rule => rule(this.date) === true);
        }
    },

    watch: {
        value: {
            immediate: true,
            handler(value) {
                if (!isEmpty(value)) {
                    let dateTime = localize(DateTime.fromISO(value, { setZone: true }));
                    let isDifferent = isEmpty(this.date) || !dateTime.equals(parse(this.date))

                    if (dateTime.isValid && isDifferent) {
                        this.date = format(dateTime);
                    }
                }
                else if (this.hasDefaultDate) {
                    this.date = format(localize(DateTime.now()));
                }
                else {
                    this.date = null;
                }
            },
        },
        date: {
            immediate: true,
            handler(date) {
                if (isEmpty(this.date)) {
                    this.$emit("input", null);
                    return;
                }

                if (!this.valid) {
                    return;
                }

                let dateTime = parse(date);
                let isoDateTime = dateTime.toISO();

                if (this.value !== isoDateTime) {
                    this.$emit("input", isoDateTime);
                }
            },
        },
    }
};
</script>

<style lang="scss">
    .app-date-field {
        &.v-text-field--enclosed.v-input--dense.v-text-field--outlined .v-input__append-inner {
            margin: 2px -10px 2px 2px;
        }
    }
</style>
